import * as React from 'react';
import { graphql } from 'gatsby';
import proptypes from 'proptypes';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import Title from '../components/Title';
import SubTitle from '../components/SubTitle';
import HTMLParser from '../components/HTMLParser';
import SEO from '../components/SEO';

const PhilosophyPage = ({ data }) => {
    const sections = data.allPhilosophyYaml.nodes.sort((a, b) => a.nr - b.nr);

    return (
        <Layout>
            <SEO
                title="Philosophy"
                description="We love working with people like you. It is good for us. It is good for you. Only zen, no  bullshit."
            />
            <Title pre="breath Deeply" post="and bring it on" isTitle>
                <h1 id="initialClaim1" className="claim">
                    ad astra
                    <br />
                    <span className="strike"> per aspera</span>
                </h1>
            </Title>
            {sections.map((section) => (
                <section
                    className="front block-image-bg"
                    id={section.id}
                    key={section.id}
                    style={{ position: 'relative' }}
                >
                    {section.image !== null && (
                        <GatsbyImage
                            image={getImage(section.image)}
                            alt=""
                            style={{
                                position: 'absolute',
                                top: '5%',
                                left: '0%',
                                width: '100%',
                                height: '90%',
                                zIndex: '0',
                            }}
                            objectFit={section.imageCover ? 'cover' : 'contain'}
                            objectPosition="center top"
                        />
                    )}
                    <div className="padded wrapper full-height magazine">
                        <SubTitle pre={section.title}>
                            <h1 className="claim"> {section.claim}</h1>
                        </SubTitle>
                        <HTMLParser yaml={section.body} />
                    </div>
                </section>
            ))}
        </Layout>
    );
};

export const philosphySections = graphql`
    query PhilosophyPageQuery {
        allPhilosophyYaml {
            nodes {
                id
                image {
                    childImageSharp {
                        gatsbyImageData(
                            quality: 100
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                        )
                    }
                }
                imageCover
                claim
                body
                title
                url
                nr
            }
        }
    }
`;

PhilosophyPage.propTypes = {
    data: proptypes.object,
};

export default PhilosophyPage;
